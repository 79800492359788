import reactImg from "./tech/reactjs.svg";
import reactNativeImg from "./tech/react-native.svg";
import nodeImg from "./tech/nodejs.svg";
import typescriptImg from "./tech/typescript.svg";
import javascriptImg from "./tech/javascript.svg";
import dockerImg from "./tech/docker.svg";
import awsImg from "./tech/aws.svg";
import mongoDbImg from "./tech/mongodb.svg";
import postgreSqlImg from "./tech/psql.svg";
import redisImg from "./tech/redis.svg";
import sheetsImg from "./tech/google-sheets.svg";
import ssrImg from "./tech/ssr.svg";
import intlImg from "./tech/intl.svg";
import microservicesImg from "./tech/microservices.svg";
import openTelemetryImg from "./tech/opentelemetry.svg";
import bigDataImg from "./tech/big_data.svg";
import dataAnalysisImg from "./tech/data_analysis.svg";
import cepImg from "./tech/cep.svg";
import materialImg from "./tech/material_ui.svg";
import mobxImg from "./tech/mobx.svg";
import nextjsImg from "./tech/nextjs.svg";
import emotionImg from "./tech/emotion.svg";
import netlifyImg from "./tech/netlify.svg";
import nestJsImg from "./tech/nestjs.svg";
import { getBlogPostUrl } from "./urls";

export const Technologies = {
  AWS: {
    name: "Amazon Web Services",
    url: "https://aws.amazon.com/",
    image: awsImg,
  },
  Node: {
    name: "Node.js",
    url: getBlogPostUrl("/benefits-of-javascript-on-the-server/"),
    image: nodeImg,
  },
  NestJS: {
    name: "NestJS",
    url: "https://nestjs.com/",
    image: nestJsImg,
  },

  React: {
    name: "React.js",
    url: getBlogPostUrl("/react-angular-vue/"),
    image: reactImg,
  },
  ReactNative: {
    name: "React Native",
    url: "https://reactnative.dev/",
    image: reactNativeImg,
  },
  Microservices: {
    name: "Microservice Architecture",
    url: getBlogPostUrl("/serveless-microservices-monolith/"),
    image: microservicesImg,
  },
  JavaScript: {
    name: "JavaScript",
    url: getBlogPostUrl("/typescript-vs-javascript/"),
    image: javascriptImg,
  },
  TypeScript: {
    name: "TypeScript",
    url: getBlogPostUrl("/typescript-vs-javascript/"),
    image: typescriptImg,
  },
  Docker: {
    name: "Docker",
    url: "https://www.docker.com/",
    image: dockerImg,
  },
  MongoDB: {
    name: "MongoDB",
    url: "https://www.mongodb.com/",
    image: mongoDbImg,
  },
  PostgreSQL: {
    name: "PostgreSQL",
    url: "https://www.postgresql.org/",
    image: postgreSqlImg,
  },
  Redis: {
    name: "Redis",
    url: "https://redis.io/",
    image: redisImg,
  },
  GSheets: {
    name: "Google Sheets",
    url: "https://developers.google.com/sheets/api",
    image: sheetsImg,
  },
  SSR: {
    name: "Server-Side Rendering",
    url: "https://en.wikipedia.org/wiki/Isomorphic_JavaScript",
    image: ssrImg,
  },
  Intl: {
    name: "Internationalization",
    url: "https://en.wikipedia.org/wiki/Internationalization_and_localization",
    image: intlImg,
  },
  OpenTelemetry: {
    name: "OpenTelemetry",
    url: "https://opentelemetry.io/",
    image: openTelemetryImg,
  },
  BigData: {
    name: "Big Data",
    url: "https://en.wikipedia.org/wiki/Big_data",
    image: bigDataImg,
  },
  DataAnalysis: {
    name: "Data Analysis",
    url: "https://en.wikipedia.org/wiki/Data_analysis",
    image: dataAnalysisImg,
  },
  CEP: {
    name: "Complex Event Processing",
    url: "https://en.wikipedia.org/wiki/Complex_event_processing",
    image: cepImg,
  },
  MaterialUI: {
    name: "MaterialUI",
    url: "https://material-ui.com/",
    image: materialImg,
  },
  MobX: {
    name: "MobX",
    url: "https://mobx.js.org/README.html",
    image: mobxImg,
  },
  NextJS: {
    name: "Next.js",
    url: "https://nextjs.org/",
    image: nextjsImg,
  },
  Emotion: {
    name: "Emotion",
    url: "https://emotion.sh/",
    image: emotionImg,
  },
  Netlify: {
    name: "Netlify",
    url: "https://www.netlify.com/",
    image: netlifyImg,
  },
};

export type Technology = keyof typeof Technologies;
